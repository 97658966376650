<template>
<div class="w-100 text-left">
    <b-modal
        v-model="modal"
        size="xl"
        title=""
        hide-footer
    >
    <b-container>
        <b-row class="container-modal-exclusive">
          <b-col>
            <div class="d-block text-center">
                <h5>Procure eventos para adicionar na lista:</h5>
            </div>
            <event-list :isCustomList='true' @addToList="changeEventToCorporateExclusive('add', $event)"/>
          </b-col>
          <b-col>
            <div class="d-block text-center">
                <h5>Somente esses eventos serão exibidos para este parceiro:</h5>
            </div>
            <b-table
                striped="striped"
                outlined="outlined"
                class="fontSize tableClicked bg-white"
                small="small"
                hover="hover"
                responsive
                show-empty
                empty-text="Sem items"
                :total-rows="allItems.length"
                :fields="fields"
                :items="allItems"
                :busy="loading"
            >

            <template slot="actions" slot-scope="data">
                <b-button
                    size="sm"
                    title="Remover parceiro"
                    variant="danger"
                    @click="changeEventToCorporateExclusive('delete', data.item)"
                >
                Remover da lista
                </b-button>
            </template>
            
            </b-table>
          </b-col>
        </b-row>
    </b-container>
    </b-modal>
</div>
</template>

<script>
import { func } from "@/functions";
import { corporateService } from '@/components/common/services/corporate';
import { eventService } from "@/components/common/services/event";
export default {
    props: {
        corporateId: {
            type: [Number, String],
            required: true
        }
    },
    mixins: [func],
    components: {
        eventList: () => import('../event/list.vue')
    },
    data(){
        return {
            modal: true,
            markAll: false,
            markAllCorporate: false,
            search: '',
            items: [],
            selectedItems: [],
            loading: false,
            fields: {
                id: {
                    label: '#Id'
                },
                name: {
                    label: 'Nome'
                },
                actions: {
                    label: ''
                }
            },
        }
    },
    watch: {
        corporateId: {
            handler: 'setSelectedItems',
            immediate: true
        },
        modal(event) {
            this.$emit('close', event)
        }
    },
    computed: {
        mayIsee() {
      return this.mayI('ev-viewer', 'ev-add', 'ev-add-date');
    },
        allItems(){
            if (this.search === '') {
                return this.items;
            }
            return this.items.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    async mounted () {
        await this.setItems()
    },
    methods: {
        async markAllItems () {
            const ids = this.allItems.map(item => item.id || item.id_corporativo) 
            await this.save(ids)
            this.search = '';
            this.markAll = false;
        },
        async markAllItemsCorporate() {
            const ids = this.allItems.map(item => item.id || item.id_corporativo) 
            await this.saveExclusive(ids)
            this.search = '';
            await this.setItems()
            this.markAllCorporate = false;
        },
        async changeEventToCorporateExclusive (type, item) {
            this.loading = true;
            if (type === 'add' && item && item.id_evento) {
                await corporateService.changeCorporateExclusive('', this.getLoggedId(), this.corporateId, item.id_evento)
            }
            if (type === 'delete') {
                await corporateService.changeCorporateExclusive('delete', this.getLoggedId(), this.corporateId, item.id_evento)
            }
            await this.setItems()
            this.loading = false;
        },
        async setItems(){
            this.loading = true;
            const items = await corporateService.listEventsByCorporateId(this.corporateId, 1, 2000)
            this.items = items;
            this.loading = false;
        },
        async setSelectedItems(){
            const selectedItems = await eventService.getExcludedCompanies(this.getLoggedId(), this.corporateId)

            this.selectedItems = selectedItems;
        },
        async addItem(id){
            const ids = this.selectedItems.map(item => item.id || item.id_corporativo) 

            ids.push(id);
            
            await this.save(ids)

        },
        async removeItem(id){
            const ids = this.selectedItems.map(item => item.id || item.id_corporativo) 

            ids.splice(ids.indexOf(id), 1);

            await this.save(ids)
        },
        async saveExclusive (ids) {
            return corporateService
                .saveExclusiveCompanies(this.getLoggedId(), this.corporateId, ids)
                .then(({ success }) => {
                    if (success){
                        return this.toastSuccess("Salvo com sucesso")
                    }
                    
                    this.toastError("Erro ao salvar")
                })
                .catch(() => this.toastError("Erro ao salvar"))
                .finally(() => this.setSelectedItems())
        },
        async save(ids){

            return eventService
                .saveExcludedCompanies(this.getLoggedId(), this.corporateId, ids)
                .then(({ success }) => {
                    if (success){
                        return this.toastSuccess("Salvo com sucesso")
                    }
                    
                    this.toastError("Erro ao salvar")
                })
                .catch(() => this.toastError("Erro ao salvar"))
                .finally(() => this.setSelectedItems())
        }
    }
}
</script>

<style>
.container-modal-exclusive {
    height: 700px;
    overflow: scroll;
}
</style>